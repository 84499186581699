import Navbar from "./components/Navbar";
import React from "react";

function App() {

  return (
    <div><Navbar /></div>
  );
}

export default App;
